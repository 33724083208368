var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"title":_vm.title,"visible":_vm.open,"maskClosable":false,"keyboard":false},on:{"cancel":_vm.importExcelHandleCancel,"ok":_vm.importExcelHandleChange}},[_c('a-spin',{attrs:{"tip":"上传中...","spinning":_vm.uploading}},[_c('a-card',{staticStyle:{"width":"100%"},attrs:{"title":"剧集导入"}},[_c('a-upload-dragger',{attrs:{"name":"file","accept":".mp4","multiple":true,"show-upload-list":false,"list-type":"picture","customRequest":_vm.handleChange},on:{"change":_vm.changeFile}},[_c('p',{staticClass:"ant-upload-drag-icon"},[_c('a-icon',{attrs:{"type":"inbox"}})],1),_c('div',[_c('font',{staticStyle:{"color":"red"}},[_vm._v("（导入的文件名请以视频集数命名。比如第一集视频名称为：1.MP4）")])],1),_c('p')]),_c('div',{staticStyle:{"margin-top":"10px","margin-bottom":"10px"}},[_c('a-card',[_c('a-row',{attrs:{"type":"flex"}},[_c('a-statistic',{attrs:{"title":"总计","value":_vm.fileList.length}}),_c('a-statistic',{style:({
          margin: '0 32px',
        }),attrs:{"title":"正在上传","value":_vm.computedUploadVideoing,"value-style":{ color: '#cf1322' }}})],1)],1)],1),_vm._l((_vm.fileList),function(item,index){return _c('div',{key:index,staticStyle:{"margin-bottom":"5px"}},[_c('div',{staticStyle:{"width":"100%","display":"flex","justify-content":"space-between"}},[_c('div',[(item.percent != 100)?_c('a-icon',{staticStyle:{"margin-right":"10px"},attrs:{"type":"loading"}}):_vm._e(),_vm._v(" "),(item.percent == 100)?_c('a-icon',{staticStyle:{"margin-right":"10px"},attrs:{"type":"link"}}):_vm._e(),_vm._v(_vm._s(item.name))],1)]),_c('div',{staticStyle:{"width":"100%","display":"flex","justify-content":"space-between"}},[(item.percent != 100)?_c('a-progress',{staticStyle:{"height":"2px"},attrs:{"percent":item.percent,"show-info":false}}):_vm._e(),_c('a-icon',{attrs:{"type":"delete"},on:{"click":function($event){$event.stopPropagation();return _vm.clickDelete(index,1)}}})],1)])})],2),_c('a-card',{staticStyle:{"width":"100%","margin-top":"10px"},attrs:{"title":"中文字幕"}},[_c('a-upload-dragger',{attrs:{"name":"file","accept":".vtt,.srt","multiple":true,"show-upload-list":false,"customRequest":_vm.handleChangeZh}},[_c('p',{staticClass:"ant-upload-drag-icon"},[_c('a-icon',{attrs:{"type":"inbox"}})],1),_c('div',[_c('font',{staticStyle:{"color":"red"}},[_vm._v("（请上传.vtt/.srt文件）")])],1),_c('p')]),_c('div',{staticStyle:{"margin-top":"10px","margin-bottom":"10px"}},[_c('a-card',[_c('a-row',{attrs:{"type":"flex"}},[_c('a-statistic',{attrs:{"title":"总计","value":_vm.fileZhList.length}}),_c('a-statistic',{style:({
          margin: '0 32px',
        }),attrs:{"title":"正在上传","value":_vm.computedUploadZhing,"value-style":{ color: '#cf1322' }}})],1)],1)],1),_vm._l((_vm.fileZhList),function(item,index){return _c('div',{key:index,staticStyle:{"margin-bottom":"5px"}},[_c('div',{staticStyle:{"width":"100%","display":"flex","justify-content":"space-between"}},[_c('div',[(item.percent != 100)?_c('a-icon',{staticStyle:{"margin-right":"10px"},attrs:{"type":"loading"}}):_vm._e(),_vm._v(" "),(item.percent == 100)?_c('a-icon',{staticStyle:{"margin-right":"10px"},attrs:{"type":"link"}}):_vm._e(),_vm._v(_vm._s(item.name))],1),(item.percent == 100)?_c('a-icon',{attrs:{"type":"delete"},on:{"click":function($event){$event.stopPropagation();return _vm.clickDelete(index,2)}}}):_vm._e()],1),(item.percent != 100)?_c('a-progress',{staticStyle:{"height":"2px"},attrs:{"percent":item.percent,"show-info":false}}):_vm._e()],1)})],2),_c('a-card',{staticStyle:{"width":"100%","margin-top":"10px"},attrs:{"title":"英文字幕"}},[_c('a-upload-dragger',{attrs:{"name":"file","accept":".vtt,.srt","multiple":true,"show-upload-list":false,"customRequest":_vm.handleChangeEn}},[_c('p',{staticClass:"ant-upload-drag-icon"},[_c('a-icon',{attrs:{"type":"inbox"}})],1),_c('div',[_c('font',{staticStyle:{"color":"red"}},[_vm._v("（请上传.vtt/.srt文件）")])],1),_c('p')]),_c('div',{staticStyle:{"margin-top":"10px","margin-bottom":"10px"}},[_c('a-card',[_c('a-row',{attrs:{"type":"flex"}},[_c('a-statistic',{attrs:{"title":"总计","value":_vm.fileEnList.length}}),_c('a-statistic',{style:({
          margin: '0 32px',
        }),attrs:{"title":"正在上传","value":_vm.computedUploadEning,"value-style":{ color: '#cf1322' }}})],1)],1)],1)],1),_c('a-card',{staticStyle:{"width":"100%","margin-top":"10px"},attrs:{"title":"西班牙字幕"}},[_c('a-upload-dragger',{attrs:{"name":"file","accept":".vtt,.srt","multiple":true,"show-upload-list":false,"customRequest":_vm.handleChangeSpain}},[_c('p',{staticClass:"ant-upload-drag-icon"},[_c('a-icon',{attrs:{"type":"inbox"}})],1),_c('div',[_c('font',{staticStyle:{"color":"red"}},[_vm._v("（请上传.vtt/.srt文件）")])],1),_c('p')]),_c('div',{staticStyle:{"margin-top":"10px","margin-bottom":"10px"}},[_c('a-card',[_c('a-row',{attrs:{"type":"flex"}},[_c('a-statistic',{attrs:{"title":"总计","value":_vm.fileSpainList.length}}),_c('a-statistic',{style:({
          margin: '0 32px',
        }),attrs:{"title":"正在上传","value":_vm.computedUploadSpaining,"value-style":{ color: '#cf1322' }}})],1)],1)],1)],1),_vm._l((_vm.fileEnList),function(item,index){return _c('div',{key:index,staticStyle:{"margin-bottom":"5px"}},[_c('div',{staticStyle:{"width":"100%","display":"flex","justify-content":"space-between"}},[_c('div',[(item.percent != 100)?_c('a-icon',{staticStyle:{"margin-right":"10px"},attrs:{"type":"loading"}}):_vm._e(),_vm._v(" "),(item.percent == 100)?_c('a-icon',{staticStyle:{"margin-right":"10px"},attrs:{"type":"link"}}):_vm._e(),_vm._v(_vm._s(item.name))],1),(item.percent == 100)?_c('a-icon',{attrs:{"type":"delete"},on:{"click":function($event){$event.stopPropagation();return _vm.clickDelete(index,3)}}}):_vm._e()],1),(item.percent != 100)?_c('a-progress',{staticStyle:{"height":"2px"},attrs:{"percent":item.percent,"show-info":false}}):_vm._e()],1)}),_c('a-modal',{attrs:{"visible":_vm.previewVisible,"footer":null},on:{"cancel":_vm.handleCancel}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"alt":"example","src":_vm.previewImage}})])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }